import { Controller } from "stimulus"
import imagesLoaded from 'imagesloaded';

export default class extends Controller {
    static targets = ["activityList", "activityText"];

    connect() {

        if (this.hasActivityListTarget && window.innerWidth > 1024 && this.activityListTarget.offsetHeight > this.activityTextTarget.offsetHeight) {
            this.element.style.marginBottom = (this.activityListTarget.offsetHeight - this.activityTextTarget.offsetHeight + 130) + "px";
        }

        window.addEventListener('resize', () => {
            if (this.hasActivityListTarget && window.innerWidth > 1024 && this.activityListTarget.offsetHeight > this.activityTextTarget.offsetHeight) {
                this.element.style.marginBottom = (this.activityListTarget.offsetHeight - this.activityTextTarget.offsetHeight + 130) + "px";
            } else {
                this.element.style.marginBottom = "";
            }
        });

    }

}