import { Controller } from "stimulus"

export default class extends Controller {

    connect() {
        //scrolltop check
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

        var isScrolling = false;
        var parallaxEl = this.element;

        window.addEventListener('scroll', () => {
            var st = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            if (!isScrolling) {
                isScrolling = true;
                window.requestAnimationFrame(() => {

                    var localScroll,
                        totalScroll,
                        actualScroll,
                        bottomWindow = st + window.innerHeight;

                    var parallaxContainerTop = parallaxEl.closest(".row").offsetTop;
                    localScroll = bottomWindow - parallaxContainerTop;
                    totalScroll = parallaxEl.offsetHeight + window.innerHeight;
                    actualScroll = (localScroll * 50) / totalScroll;

                    if (bottomWindow > parallaxContainerTop) {
                        if (window.innerWidth > 767) {
                            parallaxEl.style.transform = `translate(0%,-${actualScroll}%)`;
                        } else {
                            parallaxEl.style.transform = `translate(-50%,-${actualScroll}%)`;
                        }
                    }

                    isScrolling = false;
                })
            }
        })
       
            
        

    }
}