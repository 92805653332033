import { Controller } from "stimulus"
import gsap from 'gsap/all';
import { TimelineMax } from "gsap";

export default class extends Controller {
    static targets = ["topTitleContainer", "bottomTitleContainer"];

    connect() {

        //top slides
        this.topSlides = this.topTitleContainerTarget.querySelectorAll('.slide-title');
        var topSlidesHeight = [];
        var topSlidesDiv = [];

        this.topSlides.forEach((slide) => {
            topSlidesHeight.push(slide.offsetHeight);
            topSlidesDiv.push(slide.querySelector('div'));
        });
        var topSlidesMaxHeight = Math.max(...topSlidesHeight);
        this.topTitleContainerTarget.style.height = topSlidesMaxHeight + "px";
        this.topSlides.forEach((slide) => {
            slide.style.height = topSlidesMaxHeight + "px";
        })

        topSlidesDiv.forEach((div) => {
            gsap.set(div, { y: "-100%", force3D: true });
        });

        var topTl = gsap.timeline({ repeat: -1, repeatDelay: 3 })
        topTl.staggerFromTo(topSlidesDiv, 0.5, { y: "-100%" }, { y: "0" }, 5)
            .staggerTo(topSlidesDiv, 0.5, { y: "100%" }, 5, 5);

        //bottom slides
        this.bottomSlides = this.bottomTitleContainerTarget.querySelectorAll('.slide-title');
        var bottomSlidesHeight = [];
        var bottomSlidesDiv = [];

        this.bottomSlides.forEach((slide, index) => {
            bottomSlidesHeight.push(slide.offsetHeight);
            bottomSlidesDiv.push(slide.querySelector('div'));
        });
        var bottomSlidesMaxHeight = Math.max(...bottomSlidesHeight);
        this.bottomTitleContainerTarget.style.height = bottomSlidesMaxHeight + "px";
        this.bottomSlides.forEach((slide) => {
            slide.style.height = bottomSlidesMaxHeight + "px";
        })

        bottomSlidesDiv.forEach((div) => {
            gsap.set(div, { y: "-100%", force3D: true });
        });

        var bottomTl = gsap.timeline({ repeat: -1, delay: 1, repeatDelay: 3 })
        bottomTl.staggerFromTo(bottomSlidesDiv, 0.5, { y: "-100%" }, { y: "0"}, 5)
            .staggerTo(bottomSlidesDiv, 0.5, { y: "100%" }, 5, 5);


        //hero quote
        document.querySelector('.hero .hero__quote').classList.add('--fade-in');

        window.addEventListener('resize', () => {
            this.setSlideHeight();
        });
    }

    setSlideHeight() {
        //reset value
        this.topTitleContainerTarget.style.height = "";
        this.bottomTitleContainerTarget.style.height =  "";

        //top slides
        var topSlidesHeight = [];

        this.topSlides.forEach((slide) => {
            slide.style.height =  "";
            topSlidesHeight.push(slide.offsetHeight);
        });
        var topSlidesMaxHeight = Math.max(...topSlidesHeight);
        this.topTitleContainerTarget.style.height = topSlidesMaxHeight + "px";
        this.topSlides.forEach((slide) => {
            slide.style.height = topSlidesMaxHeight + "px";
        });

        //bottom slides
        var bottomSlidesHeight = [];

        this.bottomSlides.forEach((slide) => {
            slide.style.height = "";
            bottomSlidesHeight.push(slide.offsetHeight);
        });
        var bottomSlidesMaxHeight = Math.max(...bottomSlidesHeight);
        this.bottomTitleContainerTarget.style.height = bottomSlidesMaxHeight + "px";
        this.bottomSlides.forEach((slide) => {
            slide.style.height = bottomSlidesMaxHeight + "px";
        })
    }

    //a() {
    //    //define("TurningSentences", ["View", "SplitText"], function (t, e) {
    //        var i;
    //        return i = function (t) {
    //            function i(t) {
    //                this.destroy = __bind(this.destroy, this), this.applyTimelineStagger = __bind(this.applyTimelineStagger, this), this.splitTitle = __bind(this.splitTitle, this), this.onTlCompleted = __bind(this.onTlCompleted, this), this.ready = __bind(this.ready, this), this.init = __bind(this.init, this), i.__super__.constructor.call(this, t)
    //            }
    //            return __extends(i, t), i.prototype.init = function () {
    //                return TweenMax.delayedCall(0, this.ready)
    //            }, i.prototype.ready = function () {
    //                var t, e, i, n, r, s, o, a, l, h, c, u, d, p, f, m, g, _, v;
    //                for (
    //                    this.topTl = new TimelineMax,
    //                    this.bottomTl = new TimelineMax, this.tl = new TimelineMax({
    //                    onComplete: this.onTlCompleted
    //                }),
    //                    e = this.element.find(".top-title-container .slide-title"),
    //                    t = this.element.find(".bottom-title-container .slide-title"),
    //                    CSSPlugin.defaultTransformPerspective = 600, l = 1, r = 1, u = 6, a = (l + r + u) / 2, p = 0, s = .02, i = [], o = m = 0, _ = e.length; _ > m; o = ++m) d = e[o], i.push(p), c = o === e.length - 1 ? u - a : u,
    //                        f = this.splitTitle(d).lines,
    //                        this.applyTimelineStagger(
    //                            this.topTl, f, l, r, s, p, c), p += l + u + r;
    //                for (o = g = 0, v = t.length; v > g; o = ++g) d = t[o], n = i[o], 0 === o ? (n += a, h = u - a) : h = u,
    //                    f = this.splitTitle(d).lines, 
    //                    this.applyTimelineStagger(this.bottomTl, f, l, r, s, n, h);
    //                this.tl.add(this.topTl, 0),
    //                    this.tl.add(this.bottomTl, -a),
    //                    this.tl.play(1),
    //                    this.tl.timeScale(1.4)
    //            }, i.prototype.onTlCompleted = function () {
    //                this.tl.play(2)
    //            }, i.prototype.splitTitle = function (t) {
    //                var i;
    //                return i = new e(t, {
    //                    type: "words,lines"
    //                }), TweenMax.set(i.words, {
    //                    perspective: 400
    //                }), i
    //            }, i.prototype.applyTimelineStagger = function (t, e, i, n, r, s, o) {
    //                t.staggerFrom(e, i, {
    //                    y: -114,
    //                    opacity: 1,
    //                    force3D: !0,
    //                    ease: Expo.easeOut,
    //                    transformOrigin: "0% 50% -30px"
    //                }, r, s), t.staggerTo(e, n, {
    //                    y: 114,
    //                    opacity: 1,
    //                    force3D: !0,
    //                    ease: Expo.easeIn,
    //                    transformOrigin: "0% 50% -30px"
    //                }, r, s + i + o)
    //            }, i.prototype.destroy = function () {
    //                return null != this.tl && (this.tl.stop(), this.topTl.clear(), this.bottomTl.clear(), this.tl.clear()), i.__super__.destroy.call(this)
    //            }, i
    //        }
    //    //});
    //}

}