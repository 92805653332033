import { Controller } from "stimulus"
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import SmoothScroll from "smooth-scroll";


export default class extends Controller {
    static targets = ["menu", "alertPopup", "contactPopup"];

    /*
        Run functions ---- connect == load pagina
    */

    connect() {

        this.vh();

        window.addEventListener("resize", () => {
            this.vh();
        }, true);


        this.IEdetect();

        if (window.innerWidth < 768) {
            this.fixedHeader();
        }

        this.smoothScrollOnClick();
    }


    /*
        Utils
    */

    vh() {
        const vh = window.innerHeight * 0.01;
        this.element.style.setProperty("--vh", `${vh}px`);
    }

    IEdetect() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            document.documentElement.classList.add('ie');
        }

        return false;
    }

    bodyNoScroll() {
        disableBodyScroll(this.bodyTarget);
    }

    bodyScroll() {
        enableBodyScroll(this.bodyTarget);
    }

    elementIsInView(el) {
        const scroll = window.scrollY || window.pageYOffset
        const boundsTop = el.getBoundingClientRect().top + scroll

        const viewport = {
            top: scroll,
            bottom: scroll + window.innerHeight,
        }

        const bounds = {
            top: boundsTop,
            bottom: boundsTop + el.clientHeight,
        }

        return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom)
            || (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
    }

    getSiblings(elem) {
	    // Setup siblings array and get the first sibling
	    var siblings = [];
	    var sibling = elem.parentNode.firstChild;

	    // Loop through each sibling and push to the array
	    while (sibling) {
		    if (sibling.nodeType === 1 && sibling !== elem) {
			    siblings.push(sibling);
		    }
		    sibling = sibling.nextSibling
	    }

	    return siblings;
    };

    /*
        Header Mobile
     */
    fixedHeader() {
        //scrolltop check
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
        var isScrolling = false;

        window.addEventListener('scroll', () => {
            var st = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            if (!isScrolling) {
                isScrolling = true;
                window.requestAnimationFrame(() => {

                    if (st > 0) {
                        document.getElementById('hd').classList.add('--slide-up');
                    } else {
                        document.getElementById('hd').classList.remove('--slide-up');
                    }

                    isScrolling = false;
                })
            }
        })
    }

    /*
        Menu
     */

    toggleMenu(e) {
        e.preventDefault();

        if (document.body.style.overflow == "hidden") {
            this.bodyScroll();
        } else {
            this.bodyNoScroll();
        }
        e.currentTarget.classList.toggle('--close');
        this.menuTarget.classList.toggle('--open');
    }

    toggleSubmenu(e) {
        e.preventDefault();

        this.menuTarget.classList.toggle('--submenu-open');
        e.currentTarget.closest('li').querySelector('.submenu').classList.toggle('--open');
    }

    closeSubmenu(e) {
        e.preventDefault();

        this.menuTarget.classList.remove('--submenu-open');
        e.currentTarget.closest('.submenu').classList.remove('--open');
    }

    /*
        Smooth Scroll Click
    */
    smoothScrollOnClick() {
        new SmoothScroll('[data-smooth-scroll-click]', {
            offset: 130,
            speedAsDuration: true,
            updateURL: false,
            popstate: false
        });
    }

    /*
        Alert
     */

    showAlert(txt) {
        var alertIcon = '<div class="f-modal-icon f-modal-error animate"><span class="f-modal-x-mark"><span class="f-modal-line f-modal-left animateXLeft"></span><span class="f-modal-line f-modal-right animateXRight"></span></span><div class="f-modal-placeholder"></div><div class="f-modal-fix"></div></div>';

        this.alertPopupTarget.querySelector('.alert-icon').innerHTML = alertIcon;
        this.alertPopupTarget.querySelector('.alert-txt').innerText = txt;
        this.alertPopupTarget.classList.remove('--hide');
        this.alertPopupTarget.classList.add('--show');
    }

    /*
        Succes
     */

    showSuccess(txt) {
        var icon = '<div class="f-modal-icon f-modal-success animate"><span class="f-modal-line f-modal-tip animateSuccessTip"></span><span class="f-modal-line f-modal-long animateSuccessLong"></span><div class="f-modal-placeholder"></div><div class="f-modal-fix"></div></div>';

        this.alertPopupTarget.querySelector('.alert-icon').innerHTML = icon;
        this.alertPopupTarget.querySelector('.alert-txt').innerText = txt;
        this.alertPopupTarget.classList.remove('--hide');
        this.alertPopupTarget.classList.add('--show');
    }

    closeAlert(e) {
        e.preventDefault();

        this.alertPopupTarget.classList.remove('--show');
        this.alertPopupTarget.classList.add('--hide');
    }

    /*
        Contact Popup
     */

    openContactPopup(e) {
        e.preventDefault();

        this.contactPopupTarget.classList.remove('--close');
        this.contactPopupTarget.classList.add('--open');
    }

    closeContactPopup(e) {
        e.preventDefault();

        this.contactPopupTarget.classList.remove('--open');
        this.contactPopupTarget.classList.add('--close');
    }
}

