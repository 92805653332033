import { Controller } from "stimulus"
import Swiper from 'swiper';

export default class extends Controller {

    connect() {

        var activitySwiper = new Swiper(this.element, {
            slidesPerView: 1,
            spaceBetween: 20,
            resistanceRatio: 0,
            navigation: {
                nextEl: this.element.closest('.slider').querySelector('.swiper-button-next'),
                prevEl: this.element.closest('.slider').querySelector('.swiper-button-prev'),
            },
            pagination: {
                el: this.element.closest('.slider').querySelector('.swiper-pagination'),
                type: 'bullets',
                clickable: true
            },
            breakpoints: {
                // when window width is >= 480px
                480: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                // when window width is >= 1025px
                1025: {
                    slidesPerView: 3,
                    spaceBetween: 50
                }
            }
        });

        if (window.innerWidth >= 1025 && this.element.querySelectorAll('.swiper-slide').length <= 3) {
            this.element.closest('.slider').classList.add('--noswiping');
        }
        else if (window.innerWidth < 1025 && window.innerWidth >= 480 && this.element.querySelectorAll('.swiper-slide').length <= 2) {
            this.element.closest('.slider').classList.add('--noswiping');
        }
        else if (window.innerWidth < 480 && this.element.querySelectorAll('.swiper-slide').length <= 1) {
            this.element.closest('.slider').classList.add('--noswiping');
        }

    }

}