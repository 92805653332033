import { Controller } from "stimulus"
import imagesLoaded from 'imagesloaded';

export default class extends Controller {
    static targets = ["associateImg"];

    connect() {

        var associateContainer = this.element;
        var associateImage = this.associateImgTarget;

        imagesLoaded(associateContainer, function () {

            associateContainer.querySelector('.section').style.minHeight = associateImage.offsetHeight + "px";

        });

    }

}