import { Controller } from "stimulus"
//import gsap from 'gsap/all';

export default class extends Controller {

    connect() {

        this.chekcPositionOnLoad();

        //scrolltop check
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

        var footer = document.getElementById('ft');

        var isScrolling = false;
        var pageIntroTop = this.getElemDistance(document.querySelector('.page-intro'));

        window.addEventListener('scroll', () => {
            var st = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            if (!isScrolling) {
                isScrolling = true;
                window.requestAnimationFrame(() => {

                    if (st > pageIntroTop - 50) {
                        this.element.classList.add('--fixed');
                        //gsap.set(this.element, { x: -120, y: st - pageIntroTop});
                    } else {
                        this.element.classList.remove('--fixed');
                        //gsap.set(this.element, { x: -120, y: 0 });
                    }

                    isScrolling = false;
                })
            }
        })
    }

    chekcPositionOnLoad() {
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
        var st = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
        var pageIntroTop = this.getElemDistance(document.querySelector('.page-intro'));

        if (st > pageIntroTop - 50) {
            this.element.classList.add('--fixed');
        } else {
            this.element.classList.remove('--fixed');
        }
    }

    getElemDistance(elem) {
        var location = 0;
        if (elem.offsetParent) {
            do {
                location += elem.offsetTop;
                elem = elem.offsetParent;
            } while (elem);
        }
        return location >= 0 ? location : 0;
    };

    /*
       Share social
     */

    share(e) {
        e.preventDefault();

        const shareLink = location.href;
        const shareText = document.querySelector('.js-form-params input[name="shareText"]').value;
        const social = e.target;
        if (social.classList.contains('icon-facebook')) {
            window.open("https://www.facebook.com/sharer.php?u=" + encodeURIComponent(shareLink), "share", "height=440,width=900,scrollbars=true");
        } else if (social.classList.contains('icon-twitter')) {
            var twitterShare = "https://twitter.com/intent/tweet?url=" + encodeURIComponent(shareLink);
            if (shareText.length) {
                twitterShare = twitterShare + "&text=" + encodeURIComponent(shareText);
            }
            window.open(twitterShare, "twitter", "width=600,height=400");
        } else if (social.classList.contains('icon-whatsapp')) {
            var whatsappShare = "https://api.whatsapp.com/send?text=" + encodeURIComponent(shareText) + " " + encodeURIComponent(shareLink);
            window.open(whatsappShare, "whatsapp", "width=600,height=400");
        } else if (social.classList.contains("icon-linkedin")) {
            var linkedinShare = "https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(shareLink);
            if (shareText.length) {
                linkedinShare = linkedinShare + "&title=" + encodeURIComponent(shareText);
            }
            window.open(linkedinShare, "linkedin", "width=600,height=400");
        }
    }

    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }
}