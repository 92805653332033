import { Controller } from "stimulus"
import imagesLoaded from 'imagesloaded';
import Masonry from 'masonry-layout';

export default class extends Controller {

    connect() {

        var masnryContainer = this.element;

        imagesLoaded(masnryContainer, function () {
            new Masonry(masnryContainer, {
                percentPosition: true
            });
        });

    }

}