import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["mandatoryField", "mandatoryFieldPopup"];

    connect() {

        this.form = this.element.querySelector('form');

        //rimozione classe errore
        this.mandatoryFieldTargets.forEach((field) => {
            field.addEventListener('focus', () => {
                field.closest('.form-field').classList.remove('--error');
                if (field.type == 'radio') {
                    field.closest('.custom-radio').classList.remove('--error');
                }
            });
        });

        this.mandatoryFieldPopupTargets.forEach((field) => {
            field.addEventListener('focus', () => {
                field.closest('.form-field').classList.remove('--error');
                if (field.type == 'radio') {
                    field.closest('.custom-radio').classList.remove('--error');
                }
            });
        });
    }


    /*
        Form in pagina
     */

    submitForm(e) {
        e.preventDefault();

        var form = this.form;

        if (this.checkForm(form)) {
            this.doForm(form);
        }
    }

    checkForm(form) {
        var notice = false;

        this.mandatoryFieldTargets.forEach((field) => {
            //check input(no radio) e select
            if (field.type != 'radio' && (field.value.replace(/\s+/g, '') == '' || (field.nodeName === 'SELECT' && (field.value == null || field.value.replace(/\s+/g, '') == '0')))) {
                notice = true;
                field.closest('.form-field').classList.add('--error');
            }

            //check input radio
            if (field.type == 'radio') {
                //check privacy
                if (field.id == "privacyAgree" && !field.checked) {
                    notice = true;
                    field.closest('.custom-radio').classList.add('--error');
                }
            }
        });

        if (notice) {
            return false;
        }

        //check email
        var emailInput = form.querySelector('input[type=email][name=email]');
        if (emailInput) {
            var trimEmail = emailInput.value.replace(/\s+/g, '');
            if (!this.validateEmail(trimEmail)) {
                emailInput.closest('.form-field').classList.add('--error');
                this.mainController.showAlert('Email non valida');
                return false;
            }
        }

        return true;
    }

    doForm(form) {

        var submitButton = form.querySelector('button[name=submitBtn]');
        submitButton.disabled = true;

        const dataObj = {
            company: form.querySelector('input[name=company]').value,
            name: form.querySelector('input[name=name]').value,
            lastname: form.querySelector('input[name=lastname]').value,
            role: form.querySelector('input[name=role]').value,
            email: form.querySelector('input[name=email]').value,
            phone: form.querySelector('input[name=phone]').value,
            request: form.querySelector('textarea[name=request]').value,
            requestUrl: location.href,
            contactEmail: document.querySelector('input[type=hidden][name=contactFormEmail]').value,
            __RequestVerificationToken: document.querySelector('input[name="__RequestVerificationToken"]').value,
        };


        var loader = document.getElementById('loader');
        loader.classList.remove('--loaded');
        loader.classList.add('--loading');

        fetch('/ajax/svc.asmx/ContactRequest', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataObj)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            else {
                //Error handling
                throw new Error('response not ok');
            }
        }).then((responseJson) => {
            //console.log(responseJson, responseJson.d.status, responseJson.d.description)
            if (responseJson.d.status) {

                //richiesta inviata
                loader.classList.remove('--loading');
                loader.classList.add('--loaded');

                this.mainController.showSuccess('La tua richiesta è stata inviata');
                form.reset();

                submitButton.disabled = false;
            }
            else {

                console.log(responseJson.d.description);

                loader.classList.remove('--loading');
                loader.classList.add('--loaded');

                this.mainController.showAlert(responseJson.d.description);

                submitButton.disabled = false;
            }
        }).catch((error) => {
            console.log(error);

            loader.classList.remove('--loading');
            loader.classList.add('--loaded');

            //Error handling
            this.mainController.showAlert("Si è verificato un errore, riprova più tardi");

            submitButton.disabled = false;
        });
    }


    /*
        Form popup
     */

    submitPopupForm(e) {
        e.preventDefault();

        var form = this.form;

        if (this.checkPopupForm(form)) {
            this.doPopupForm(form);
        }
    }

    checkPopupForm(form) {

        var notice = false;

        this.mandatoryFieldPopupTargets.forEach((field) => {
            //check input(no radio) e select
            if (field.type != 'radio' && (field.value.replace(/\s+/g, '') == '' || (field.nodeName === 'SELECT' && (field.value == null || field.value.replace(/\s+/g, '') == '0')))) {
                notice = true;
                field.closest('.form-field').classList.add('--error');
            }

            //check input radio
            if (field.type == 'radio') {
                //check privacy
                if (field.id == "popupPrivacyAgree" && !field.checked) {
                    notice = true;
                    field.closest('.custom-radio').classList.add('--error');
                }
            }
        });

        if (notice) {
            return false;
        }

        //check email
        var emailInput = form.querySelector('input[type=email][name=popupEmail]');
        if (emailInput) {
            var trimEmail = emailInput.value.replace(/\s+/g, '');
            if (!this.validateEmail(trimEmail)) {
                emailInput.closest('.form-field').classList.add('--error');
                this.mainController.showAlert('Email non valida');
                return false;
            }
        }

        return true;
    }

    doPopupForm(form) {
        var submitButton = form.querySelector('button[name=popupSubmitBtn]');
        submitButton.disabled = true;

        const dataObj = {
            company: form.querySelector('input[name=popupCompany]').value,
            name: form.querySelector('input[name=popupName]').value,
            lastname: form.querySelector('input[name=popupLastname]').value,
            role: form.querySelector('input[name=popupRole]').value,
            email: form.querySelector('input[name=popupEmail]').value,
            phone: form.querySelector('input[name=popupPhone]').value,
            request: form.querySelector('textarea[name=popupRequest]').value,
            requestUrl: location.href,
            contactEmail: document.querySelector('input[type=hidden][name=contactFormEmail]').value,
            __RequestVerificationToken: document.querySelector('input[name="__RequestVerificationToken"]').value,
        };

        var loader = document.getElementById('loader');
        loader.classList.remove('--loaded');
        loader.classList.add('--loading');

        fetch('/ajax/svc.asmx/ContactRequest', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataObj)
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            else {
                //Error handling
                throw new Error('response not ok');
            }
        }).then((responseJson) => {
            //console.log(responseJson, responseJson.d.status, responseJson.d.description)
            if (responseJson.d.status) {

                //richiesta inviata
                loader.classList.remove('--loading');
                loader.classList.add('--loaded');

                this.mainController.showSuccess('La tua richiesta è stata inviata');
                form.reset();
                submitButton.disabled = false;
            }
            else {

                console.log(responseJson.d.description);

                loader.classList.remove('--loading');
                loader.classList.add('--loaded');

                this.mainController.showAlert(responseJson.d.description);

                submitButton.disabled = false;
            }
        }).catch((error) => {
            console.log(error);

            loader.classList.remove('--loading');
            loader.classList.add('--loaded');

            //Error handling
            this.mainController.showAlert("Si è verificato un errore, riprova più tardi");

            submitButton.disabled = false;
        });
    }


    /*
        Validazione Email
    */
    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    /*
        Main Controller
    */

    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }
}