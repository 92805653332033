import { Controller } from "stimulus"
//import gsap from 'gsap/all';

export default class extends Controller {

    connect() {

        //scrolltop check
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

        var isScrolling = false;
        var newsCategoryTop = this.getElemDistance(this.element);

        window.addEventListener('scroll', () => {
            var st = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            if (!isScrolling) {
                isScrolling = true;
                window.requestAnimationFrame(() => {

                    if (window.innerWidth < 768) {
                        if (st > newsCategoryTop - this.element.offsetHeight) {
                            this.element.querySelector('.container').classList.add('--fixed');
                        } else {
                            this.element.querySelector('.container').classList.remove('--fixed');
                        }
                    } else {
                        if (st > newsCategoryTop ) {
                            this.element.querySelector('.container').classList.add('--fixed');
                        } else {
                            this.element.querySelector('.container').classList.remove('--fixed');
                        }
                    }

                   
                    isScrolling = false;
                })
            }
        })
    }

    getElemDistance(elem) {
        var location = 0;
        if (elem.offsetParent) {
            do {
                location += elem.offsetTop;
                elem = elem.offsetParent;
            } while (elem);
        }
        return location >= 0 ? location : 0;
    };

    /*
       Toggle Dropdown
     */

    toggleCategory(e) {
        e.preventDefault();

        e.currentTarget.classList.toggle('--toggle');
        this.element.querySelector('ul').classList.toggle('--open');
    }


    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }
}