import { Controller } from "stimulus"

export default class extends Controller {

    connect() {

        this.chekcPositionOnLoad();

        //scrolltop check
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

        var footer = document.getElementById('ft');

        var isScrolling = false;

        window.addEventListener('scroll', () => {
            var st = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
            if (!isScrolling) {
                isScrolling = true;
                window.requestAnimationFrame(() => {

                    var footerIsInView = this.mainController.elementIsInView(footer);
                    if (footerIsInView) {
                        //this.element.classList.add('--slide-in');
                        this.element.classList.add('--fixed');
                    } else {
                        this.element.classList.remove('--fixed');
                        if (st > 50) {
                            this.element.classList.add('--slide-in');
                        } else {
                            this.element.classList.remove('--slide-in');
                        }
                    }

                    isScrolling = false;
                })
            }
        })
    }

    chekcPositionOnLoad() {
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
        var st = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
        var footer = document.getElementById('ft');
        var footerIsInView = this.mainController.elementIsInView(footer);
        if (footerIsInView) {
            //this.element.classList.add('--slide-in');
            this.element.classList.add('--fixed');
        } else {
            this.element.classList.remove('--fixed');
            if (st > 50) {
                this.element.classList.add('--slide-in');
            } else {
                this.element.classList.remove('--slide-in');
            }
        }
    }

    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }
}