import { Controller } from "stimulus"

export default class extends Controller {

    connect() {
        //url
        if (window.location.href.indexOf('#tag=') > -1) {
            var urlTagFilterValue = window.location.href.split('#tag=')[1];

            if (urlTagFilterValue != "none") {
                //bottone
                var tagButtons = document.querySelectorAll('.news-tags a[data-tag]');
                tagButtons.forEach((btn) => {
                    if (btn.getAttribute('data-tag') == urlTagFilterValue) {
                        btn.classList.add('--selected');
                    }
                });

                //news
                var newsList = document.querySelectorAll('.news-container .box.--news');
                newsList.forEach((box) => {
                    if (box.hasAttribute('data-tag-' + urlTagFilterValue)) {
                        box.style.display = '';
                    } else {
                        box.style.display = 'none';
                    }
                });
            }
        }
    }

    filterByTag(e) {
        e.preventDefault();

        var tagFilterValue = e.currentTarget.dataset.tag;
        var tagFilter = "data-tag-" + tagFilterValue;
       
        if (e.currentTarget.classList.contains('--selected')) {
            //resetto filtri se clicco sul tag già selezionato

            //url
            if (window.location.href.indexOf('#tag=') > -1) {
                window.location.href = window.location.href.split('#tag=')[0] + "#tag=none";
            }

            //bottoni
            e.currentTarget.classList.remove('--selected');
            //news
            var newsList = document.querySelectorAll('.news-container .box.--news');
            newsList.forEach((box) => {
                    box.style.display = '';
            });
        } else {
            //filtro news se clicco su un tag diverso da quello già selezionato

            //url
            if (window.location.href.indexOf('#tag=') > -1) {
                window.location.href = window.location.href.split('#tag=')[0] + "#tag=" + tagFilterValue;
            } else {
                window.location.href = window.location.href + "#tag=" + tagFilterValue;
            }

            //bottoni
            e.currentTarget.classList.add('--selected');
            this.mainController.getSiblings(e.currentTarget.closest('li')).forEach((sibling) => {
                sibling.querySelector('a').classList.remove('--selected');
            });
            //news
            var newsList = document.querySelectorAll('.news-container .box.--news');
            newsList.forEach((box) => {
                if (box.hasAttribute(tagFilter)) {
                    box.style.display = '';
                } else {
                    box.style.display = 'none';
                }
            });
        }
    }

    get mainController() {
        return this.application.getControllerForElementAndIdentifier(document.body, "main");
    }
}